import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import AppContainer from './containers/AppContainer';
import reportWebVitals from './reportWebVitals';

import { configureStore, Middleware } from '@reduxjs/toolkit';

import { createLogger } from 'redux-logger'
import {thunk} from 'redux-thunk';

import appReducer from './store/reducers';
import initialState from './store/initial';
import { Provider } from 'react-redux';
import './i18n';
import i18n from 'i18next';
import { configGetFetch, libraryGetFetch, uiGoTo, uiSetCode, uiSetCountry, uiSetCustomBaseColor, uiSetCustomContrastColor } from './store/actions';

declare global {
    interface Window {
        SALES: boolean;
    }
}

let applicationStore = configureStore({reducer: appReducer, preloadedState: initialState, middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(thunk)
});

let loggerMiddleware : Middleware|null = null;
if(process.env.NODE_ENV !== 'production') {
    loggerMiddleware = createLogger();
    applicationStore = configureStore({reducer: appReducer, preloadedState: initialState, middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware().concat(thunk).concat(loggerMiddleware as Middleware)
    });
}
let dispatch = applicationStore.dispatch as any;

const search = window.location.search;
const params = new URLSearchParams(search);
const code = params.get('code');
var lang = params.get('lang');
const route = params.get('route');
const customBase = params.get('customBase');
const customContrast = params.get('customContrast');
const sales = params.get('sales');
var country = params.get('country');

const urlParts = window.location.pathname.split('/');

for (let i = 0; i < urlParts.length; i++) {
    const part = urlParts[i];
    if(part === "DE" || part === "AT" || part === "CH") {
        country = part;
    }
    if(part === "de" || part === "en" || part === "fr") {
        lang = part;
    }
}
if (country !== null) {
    // Todo(daniel): Create common list of countries and only set by id.
    if (country === "DE" || country === "de") {
        dispatch(uiSetCountry({
            id: 'de',
            currency: 'eur',
            vat: 0.19
        }));
    } else if (country === "AT" || country === "at") {
        dispatch(uiSetCountry({
            id: 'at',
            currency: 'eur',
            vat: 0.2
        }));
    } else if (country === "CH" || country === "ch") {
        //dispatch(uiSetCountry({
        //    id: 'ch',
        //    currency: 'chf',
        //    vat: 0.077
        //}));
    }
}

if (lang === null) {
    // Check browser language if no language specified.
    var userLang = navigator.language.split('-')[0];
    if (userLang === 'de' || userLang === 'en' || userLang === 'fr') {
        lang = userLang;
    } 
}

if (lang !== null) {
    i18n.changeLanguage(lang);
}

if (code !== null) {
    dispatch(uiSetCode(code));
    dispatch(configGetFetch());
}

if (route !== null) {
    dispatch(uiGoTo(route));
}

if (customBase !== null) {
    dispatch(uiSetCustomBaseColor('#' + customBase));
}
if (customContrast !== null) {
    dispatch(uiSetCustomContrastColor('#' + customContrast));
}

if (sales !== null) {
    window.SALES = true;
}

dispatch(libraryGetFetch());

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <Provider store={applicationStore}>
            <AppContainer />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
