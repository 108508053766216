import { ColorData, ConfigState, CountryData, LibraryData, ProductData } from "../store/interfaces";

export async function copyTextToClipboard(text: string) {
    if (navigator.clipboard !== undefined) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}

export function GetHomeLink(country: CountryData) {
    if(country.id === 'at') {
        return 'https://www.engelbert-strauss.at';
    } else if(country.id === 'ch') {
        return 'https://www.engelbert-strauss.ch';
    } else {
        return 'https://www.engelbert-strauss.de';
    }
}

export function GetEULALink(country: CountryData) {
    if(country.id === 'at') {
        return 'https://www.engelbert-strauss.at/Rechtliches/AGB/Mymotionkonfigurator';
    } else if(country.id === 'ch') {
        return 'https://www.engelbert-strauss.ch/Rechtliches/AGB/Mymotionkonfigurator';
    } else {
        return 'https://www.engelbert-strauss.de/Rechtliches/AGB/Mymotionkonfigurator';
    }
}

export function GetGDPRLink(country: CountryData) {
    if(country.id === 'at') {
        return 'https://www.engelbert-strauss.at/Rechtliches/Mymotionkonfigurator_Datenschutz';
    } else if(country.id === 'ch') {
        return 'https://www.engelbert-strauss.ch/Rechtliches/Mymotionkonfigurator_Datenschutz';
    } else {
        return 'https://www.engelbert-strauss.de/Rechtliches/Mymotionkonfigurator_Datenschutz';
    }
}

export function GetImpressumLink(country: CountryData) {
    if(country.id === 'at') {
        return 'https://www.engelbert-strauss.at/Rechtliches/Impressum';
    } else if(country.id === 'ch') {
        return 'https://www.engelbert-strauss.ch/Rechtliches/Impressum';
    } else {
        return 'https://www.engelbert-strauss.de/Rechtliches/Impressum';
    }
}

export function GetBasicImageLink(baseColor?: ColorData, contrastColor?: ColorData) {
    if (baseColor === undefined || contrastColor === undefined) {
        return null;
    }

    // Currently not supported for basics.
    if(baseColor.id === '09' || baseColor.id === '10' || baseColor.id === '11' || baseColor.id === '12' || baseColor.id === 'customBase') {
        return null;
    }
    if(contrastColor.id === '10' || contrastColor.id === '04' || baseColor.id === 'customContrast') {
        return null;
    }

    return 'images/basics/' + baseColor.id + contrastColor.id + '.png';
}

export function GetBasicsLink(language: string, contrastColor?: ColorData) {
    if (contrastColor === undefined) {
        return '';
    }
    let searchString = (contrastColor.name as any)[language] as string;
    if (contrastColor.searchString !== undefined) {
        searchString = (contrastColor.searchString as any)[language] as string;
    }

    if (language === 'en') {
        return 'https://www.engelbert-strauss.de/en/s/search?filterParams=e_s_Collection_lines:Basics%2FMen_Women_Children:Men,Women%2FPersonalisation:Yes&query=' + searchString
    } else if(language === 'fr') {
        return 'https://www.engelbert-strauss.de/fr/s/recherche?filterParams=e_s_Collections:Basics%2FHommes_Femmes_Enfants:Femmes,Hommes%2FPersonnalisation:Oui&query=' + searchString
    }
    return 'https://www.engelbert-strauss.de/s/suche?filterParams=e_s_Kollektionslinien:Basics%2FHerren_Damen_Kinder:Damen,Herren%2FPersonalisierung:Ja&query=' + searchString
}

export function IsBaseColorAllowed(baseColor?: ColorData, selectedContrastColor?: ColorData) {
    if(selectedContrastColor === undefined || baseColor === undefined) {
        return true;
    }

    // Currently white and high-vis contrast is not allowed.
    if(baseColor.id === '06' && (selectedContrastColor.id === '14' || selectedContrastColor.id === '15')) {
        return false;
    }

    return true;
}

export function IsContrastColorAllowed(contrastColor?: ColorData, selectedBasetColor?: ColorData) {
    if(selectedBasetColor === undefined || contrastColor === undefined) {
        return true;
    }

    // Currently white and high-vis contrast is not allowed.
    if(contrastColor.id === '14' && (selectedBasetColor.id === '06')) {
        return false;
    }
    if(contrastColor.id === '15' && (selectedBasetColor.id === '06')) {
        return false;
    }

    return true;
}

export function LightenDarkenColor(color: string, percent: number) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = Math.floor(R * (100 + percent) / 100);
    G = Math.floor(G * (100 + percent) / 100);
    B = Math.floor(B * (100 + percent) / 100);

    R = (R<255) ? R : 255;  
    G = (G<255) ? G : 255;  
    B = (B<255) ? B : 255;  

    var RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));

    return "#" + RR + GG + BB;
}

export function IsDarkColor(color: string) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    if(R + G + B > 400) {
        return false;
    } else {
        return true;
    }
}

export function generateBackgroundStyle(baseColor: string, center: boolean) : string {
    const color0 = baseColor;
    const color1 = LightenDarkenColor(baseColor, -16);
    const color2 = LightenDarkenColor(baseColor, -49);
    /*
    0, 49, 175
    0, 41, 148
    0, 25, 88
    */
    return `transparent radial-gradient(closest-side at ${center ? '50%' : '30%'} 50%, ${color0} 0%, ${color1} 46%, ${color2} 100%) 0% 0% no-repeat padding-box`;
}

export function generateGenderStroke(baseColor: ColorData|undefined) : string {
    if (baseColor === undefined) {
        return '#ffffff';
    }

    const color1 = LightenDarkenColor(baseColor.bgColor, -70);
    return `${color1}`;
}

export function generateBackgroundSplashContrastFill(baseColor: ColorData|undefined) : string {
    if (baseColor === undefined) {
        return '#ffffffff';
    }
    return `${baseColor.bgColor}80`;
}

export function generateBackgroundSplashBaseFill(baseColor: ColorData|undefined) : string {
    if (baseColor === undefined) {
        return '#ffffffff';
    }
    return `${baseColor.color}CC`;
}

export function getMinimumOrderAmount(product: ProductData, library: LibraryData): number {
    for (let i = 0; i < library.minimumOrderAmount.products.length; i++) {
        const entry = library.minimumOrderAmount.products[i];
        if(entry.articles.includes(product.article)) {
            return entry.amount;
        }
    }
    return library.minimumOrderAmount.defaultAmount;
}

export function getTotalProductOrderAmountForMinimumOrder(product: ProductData, config: ConfigState, library: LibraryData) {
    let articlesToCount: string[] = [];
    for (let i = 0; i < library.minimumOrderAmount.products.length; i++) {
        const entry = library.minimumOrderAmount.products[i];
        if(entry.articles.includes(product.article)) {
            articlesToCount = [...entry.articles];
            break;
        }
    }
    if(articlesToCount.length === 0) {
        articlesToCount.push(product.article);
    }

    let totalAmount = 0;
    for (let i = 0; i < config.products.length; i++) {
        const products = config.products[i];
        if(articlesToCount.includes(products.article)) {
            for (let o = 0; o < products.order.length; o++) {
                const order = products.order[o];
                totalAmount += order.amount;
            }
        }
    }

    return totalAmount;
}