let translation = 
{
    "translation": {
        "key": "de",
        "mobile": {
            "landscape": "Bitte drehen Sie Ihr Gerät in den Portrait-Modus.",
            "info": {
                "header": "Benötigen Sie Hilfe bei Ihrer Konfiguration?",
                "text": "Bei Fragen zum mymotion-Konfigurator stehen wir Ihnen gerne zur Verfügung.<br>Kontaktieren Sie uns: +49 60 50 / 97 10 12"
            }
        },
        "cookie": {
            "ok": "OK",
            "message": "Engelbert Strauss setzt auf dieser Internetseite Cookies ein. Weitere Informationen finden Sie in unserer <a target=\"_blank\" href=\"https://www.engelbert-strauss.de/Rechtliches/Mymotionkonfigurator_Datenschutz\">Datenschutzerklärung</a>."
        },
        "splashscreen": {
            "link": "PERSÖNLICHE E.S. MYMOTION GESTALTEN",
            "code": {
                "placeholder": "CI-MY12345",
                "continue": "Fortfahren",
                "info": "Sie haben bereits ein Design gespeichert?<br>Geben Sie hier Ihren Code ein."
            }
        },
        "logos": {
            "standard": "Standard",
            "stitchedBlack": "Schwarz gestickt",
            "stitchedWhite": "Weiss gestickt"
        },
        "gender": {
            "female": "Damen",
            "male": "Herren"
        },
        "tags": {
            "motion2020": "Motion 2020",
            "motion": "Motion",
            "pants": "Hosen",
            "jackets": "Jacken"
        },
        "notfound": {
            "title": "SEITE NICHT GEFUNDEN",
            "subtitle": "Die Seite, die Sie aufrufen wollen, existiert nicht.<br>Sie haben sich eventuell vertippt oder die Seite wurde verschoben."
        },
        "editscreen": {
            "inspire": "Lassen Sie sich inspirieren",
            "orderlist": "Bestellliste",
            "orderlistView": "Bestellliste ansehen",
            "products": "Produkte",
            "colors": "Ihre Farben",
            "logos": "Ihr Logo",
            "baseColor": "Basisfarbe",
            "contrastColor": "Kontrastfarbe",
            "straussLogo": "Strauss Logo",
            "save": "mymotion speichern",
            "chooseproduct": "Produkte auswählen",
            "selection": "Auswahl",
            "incVat": "mit MwSt.",
            "pricingFrom": "ab {{amount}} Stück: {{price}} mit MwSt.",
            "pricingFromNoVat": "ab {{amount}} Stück: {{price}} ohne MwSt.",
            "pricingInfo": "Mehr kaufen, mehr sparen – bei höherer Stückzahl profitieren Sie von einem günstigeren Stückpreis.",
            "minAmount": "Die Mindestbestellmenge für ein Produkt beträgt {{amount}} Stück und kann durch Kombinieren verschiedener Größen sowie der jeweiligen Damen- mit der Herren-Variante erreicht werden.",
            "incVentilation": "Mit Reißverschlussbelüftung an den hinteren Oberschenkeln",
            "excVentilation": "Ohne Reißverschlussbelüftung an den hinteren Oberschenkeln",
            "invVentilationShort": "Mit Belüftung",
            "excVentilationShort": "Ohne Belüftung",
            "ventilationInfo": "<b>Reißverschlussbelüftung an den hinteren Oberschenkeln</b><br>Ihre individuellen e.s. mymotion 2020 CI Produkte können mit oder ohne Reißverschlussbelüftung an den hinteren Oberschenkeln gefertigt werden.",
            "configProduct": "Konfigurieren",
            "basics": "PASSENDE BASICS ENTDECKEN",
            "addToOrderList": "zur Bestellliste hinzufügen",
            "addToOrderListAlreadyOn": "bereits hinzugefügt",           
            "personalize": "Personalisieren",
            "changeProduct": "Produkt wechseln",
            "productDetails": "Produktdetails",
            "showAdditionalInfo": "Produktbeschreibung ansehen",
            "closeAdditionalInfo": "Produktbeschreibung ausblenden",
            "logo": {
                "header": "IHR LOGO",
                "intro": "Ihr Logo als unverwechselbares Highlight. Entscheiden Sie sich für eine Personalisierungsart und laden Sie bis zu zwei Logos hoch. Wählen Sie anschließend die gewünschte Platzierungsfläche und die Logogröße aus. Haben Sie noch keine passende Logo-Datei vorliegen, wählen Sie die entsprechende Option aus. Unser Expertenteam hilft Ihnen im Anschluss an den Bestellprozess persönlich weiter. Falls Sie keine Logo-Personalisierung auf den Produkten wünschen, fügen Sie diese einfach ohne Logos Ihrer Bestellliste hinzu.",
                "info": "Hinweis",
                "infoText": "Die nummerierten Flächen am Produkt zeigen die Möglichkeiten der Logo-Platzierung. Eine Vorschau, wie Ihr Logo auf dem Produkt aussieht, erhalten Sie von unserem Expertenteam im Anschluss an Ihre Bestellanfrage als PDF im Beratungsgespräch.",
                "upload": {
                    "header": "Logo-Upload",
                    "info": "Laden Sie bis zu 2 unterschiedliche Logos hoch, um Ihre Kleidung zu personalisieren.",
                    "drag": "Ziehen Sie Ihr Logo hier herein, um es hochzuladen.",
                    "or": "ODER",
                    "browse": "Dateien durchsuchen",
                    "fileformats": "Unterstützte Dateiformate: <b>.EPS</b>, <b>.AI</b> <b>.PDF</b>, <b>.CDR</b> oder <b>.JPG</b> - Maximale Dateigröße: <b>19MB</b>",
                    "rights": "Mit Hochladen eines Logos bestätigen Sie, nicht gegen Namens-, Marken- und Urheberrechte sowie sonstige Rechte Dritter und Gesetze zu verstoßen. Alle rechtlichen Informationen finden Sie <a target=\"_blank\" href=\"https://www.engelbert-strauss.de/Rechtliches/Mymotionkonfigurator_Datenschutz\">hier.</a>"
                },
                "type": {
                    "print": "Transferdruck",
                    "stitch": "Direkteinstickung"
                },
                "transferInfo": "Der Veredelungsallrounder:<br>Beständiger Foliendruck für fast alle Textilien bis zu 900 cm² - Sonderfarben und Verläufe möglich.<br><br>Je höher die Bestellmenge, desto niedriger der Preis für Ihre Personalisierung.<br>(Preise richten sich nach der Logofläche in cm²)",
                "directInfo": "Besonders edel und strapazierfähig: Ihre Veredelung wird direkt eingestickt - viele Schriftarten, Strickmuster und Farben möglich.<br><br>Je höher die Bestellmenge, desto niedriger der Preis für Ihre Personalisierung.<br>(Preise richten sich nach der Logofläche in cm²)",
                "add": "Erstes Logo platzieren",
                "addAdditional": "Zweites Logo platzieren",
                "existing": "Bereits platzierte Logos",
                "placement": "Platzierung",
                "placementEmpty": "Platzierung wählen",
                "placementHelp": "Klicken Sie links auf eine Fläche, oder wählen Sie unten eine Fläche aus, um ein Logo anzubringen.",
                "noLogo": "Ohne Logo fortfahren",
                "sizeEmpty": "Größe wählen",
                "place": "Logo platzieren",
                "placeFirst": "Logo platzieren und zur Bestelliste hinzufügen",
                "area": "Fläche",
                "front": "vorne",
                "back": "hinten",
                "side": "seitlich",
                "uploadFirst": "Firmenlogo hochladen",
                "uploadSecond": "Zweites Logo hochladen"
            },
            "additionalProductInfos": {
                "knee": "<b>Zusätzliche Norm durch Einsatz von Kniepolstern</b><br>Kniend Arbeiten - das bedeutet enorme Belastung für Ihre Knie! Dabei drohen chronische Erkrankungen und Verletzungen durch Gegenstände am Boden. Schützen Sie Ihre Knie vor diesen Gefahren.<br><br><b>So wird Knieschutz draus:</b><br>Diese Hose erfüllt die Anforderungen des \"Knieschutzes für Arbeiten in kniender Haltung\" Typ 2, Leistungsstufe 1 nach DIN EN 14404:2004+A1:2010 in Kombination mit den dafür zertifizierten e.s. Kniepolstern.",
                "zipper": "<b>Reißverschlussbelüftung an den hinteren Oberschenkeln</b><br>Ihre individuellen e.s. MyMotion 2020 CI Produkte können mit oder ohne Reißverschlussbelüftung an den hinteren Oberschenkeln gefertigt werden.",
                "jacketzipper": ""
            }
        },
        "save": {
            "header": "IHRE MYMOTION SPEICHERN",
            "intro": "Sie können Ihre Konfiguration speichern, um sie später fortzusetzen oder zu teilen. Kopieren Sie dazu einfach den unten stehenden Code.",
            "choices": "IHRE BISHERIGE AUSWAHL",
            "code": "IHR INDIVIDUELLER CODE",
            "copyCode": "Code kopieren",
            "info": "Wir speichern Ihre Konfiguration für die nächsten 60 Tage. In diesem Zeitraum können Sie jederzeit darauf zugreifen.",
            "copied": "Der Code wurde in die Zwischenablage kopiert!"
        },
        "inspirationscreen": {
            "header": "LASSEN SIE SICH VON UNSEREN WORKWEAR DESIGNERN INSPIRIEREN",
            "use": "DIESE KOMBINATION ÜBERNEHMEN"
        },
        "orderscreen": {
            "backToOverview": "Zurück zur Übersicht",
            "header": "Gute Wahl! Das sind Ihre Artikel",
            "subHeader": "Bitte wählen Sie die Anzahl und Größen je Artikel und vervollständigen Sie die Bestellliste.",
            "headerEmpty": "Sehr schade...",
            "subHeaderEmpty": "Ihre Bestellliste ist noch leer.",
            "priceWithoutVat": "{{price}} ohne MwSt.",
            "priceWithVat": "{{price}} mit MwSt.",
            "minimumOrder": "Die Mindestbestellmenge für ein Produkt beträgt {{pieces}} Stück und kann durch Kombinieren verschiedener Größen sowie der jeweiligen Damen- mit der Herren-Variante erreicht werden.",
            "amount": "Anzahl",
            "size": "Größe",
            "addSize": "Größe hinzufügen",
            "removeSize": "Größe entfernen",
            "nextStep": "Nächster Schritt",
            "sum": {
                "totalNoVat": "Gesamtsumme",
                "shipping": "Versandkosten",
                "free": "frei",
                "additionalVat": "+ MwSt.",
                "totalWithVat": "Gesamtkosten der Artikel",
                "withVat": "mit MwSt.",
                "additionalCosts": "zzgl. Personalisierungskosten"
            },
            "personalized": {
                "consultingHeader": "Beratung zu personalisierten<br>mymotion Produkten",
                "callHeader": "Wir rufen Sie an",
                "callText": "Wir kontaktieren Sie schnellstmöglich bzgl. Art, Platzierung und Preis Ihrer Personalisierung."
            },
            "basics": {
                "header": "Möchten Sie passende Basics zu Ihrer Konfiguration?",
                "text": "Unser Kundenservice komplettiert gemeinsam mit Ihnen Ihr Outfit um passende Shirts und Sweatshirts aus unserem Sortiment."
            },
            "inspectColors": {
                "header": "Möchten Sie Ihre Farbauswahl vorab prüfen?",
                "text": "Wir bieten Ihnen den kostenlosen Versand von Stoffmustern mit allen MyMotion-Farben an. So können Sie die verschiedenen Farbabstufungen mit Ihrer aktuell getroffenen Auswahl abgleichen."
            },
            "inspectSize": {
                "header": "Möchten Sie Ihre Größenauswahl vorab prüfen?",
                "text": "Sie sind unsicher, welche Größen Ihr Team benötigt? Gerne senden wir Ihnen kostenfrei verschiedene Größenmuster zu. Diese werden noch nicht mit Ihrer individuellen Konfiguration ausgeliefert."
            },
            "getInspired": "Lassen Sie sich inspirieren",
            "addProducts": "Produkte auswählen"
        },
        "progress": {
            "orderlist": "Bestellliste",
            "customerdata": "Kundendaten",
            "overview": "Bestellübersicht"
        },
        "current": {
            "header": "Ihre aktuelle Konfiguration",
            "uploadedLogo": "Hochgeladenes Logo",
            "save": "Speichern und teilen Sie Ihre Konfiguration"
        },
        "additionalProducts": {
            "header": "Fügen Sie weitere Produkte hinzu",
            "addNow": "Jetzt hinzufügen",
            "price": "<b>ab {{price}}</b> (ohne MwSt.)",
            "fromAmount": "ab {{pieces}} St."
        },
        "customerscreen": {
            "contact": {
                "orderFor": "Bestellung für:",
                "customerNumber": "Kundennummer (optional)",
                "firstName": "Vorname",
                "lastName": "Nachname",
                "companyName": "Firmenname",
                "companyContact": "Ansprechpartner",
                "country": "Land",
                "zipCode": "PLZ",
                "city": "Stadt/Ort",
                "address": "Adresse",
                "title": "Anrede",
                "occupation": "Beruf",
                "sector": "Branche",
                "email": "E-Mail Adresse",
                "phone": "Telefonnummer",
                "comment": "Kommentar (optional)",
                "titleFemale": "Frau",
                "titleMale": "Herr"
            },
            "orderFor": {
                "company": "Firma",
                "practice": "Praxis",
                "private": "Privat"
            },
            "backToOrderscreen": "Zurück zur Bestellliste"
        },
        "finalscreen": {
            "backToCustomerscreen": "Zurück zu Kundendaten",
            "header": "Letzter Check - alles o.k.?",
            "subHeader": "Bitte prüfen Sie alle Daten, bevor Sie Ihre Anfrage abschicken.",
            "furtherActions": {
                "header": "SO GEHT'S WEITER",
                "step1Header": "Finalisierung mit unserem Kundenservice",
                "step1Text": "Unser Kundenservice wird sich in Kürze mit Ihnen in Verbindung setzen, um Ihre Wünsche bestmöglich umzusetzen.",
                "step2Header": "Start der Produktion",
                "step2Text": "Ihre Bestellung wird an unsere Produktion weitergeleitet und in Auftrag gegeben. Ihre persönlichen mymotion-Produkte werden nun produziert.",
                "step3Header": "Versand Ihrer mymotion Produkte",
                "step3Text": "Ihre Bestellung ist jetzt auf dem Weg zu Ihnen."
            },
            "policyAccept": "Ich stimme zu",
            "eula": "Ich habe die <a target=\"_blank\" href=\"{{link}}\">Allgemeinen Geschäftsbedingungen</a> für den „CI MYMOTION KONFIGURATOR“ gelesen und akzeptiere sie.",
            "send": "Absenden",
            "error": "Es gab einen Fehler beim Absenden Ihrer Bestellung. Bitte versuchen Sie es noch einmal und kontaktieren Sie uns unter +49 60 50 / 97 10 12 falls dieser Fehler weiterhin auftritt."
        },
        "postscreen": {
            "title": "VIELEN DANK FÜR IHRE BESTELLUNG",
            "summary": "Wir werden uns so bald wie möglich bei Ihnen melden. Sie erhalten in Kürze eine Eingangsbestätigung sowie eine Zusammenfassung Ihrer Bestellung per E-Mail. <br><br>Wenn Sie Fragen haben, zögern Sie bitte nicht, uns eine E-Mail zu schreiben:<br>auftrag-mymotion@strauss.de",
            "toShop": "Zurück zum Onlineshop",
            "nextSteps": "NÄCHSTE SCHRITTE"
        },
        "exitscreen": {
            "title": "KONFIGURATOR BEENDEN",
            "text": "Möchten Sie den Konfigurator wirklich verlassen? Sie können Ihre aktuelle Konfiguration mit Ihrem Erstellercode unten speichern:",
            "cancel": "ABBRECHEN",
            "quit": "VERLASSEN"
        },
        "linkfooter": {
            "home": "Engelbert Strauss",
            "impressum": "Impressum",
            "eula": "AGB",
            "gdpr": "Datenschutz",
            "osplatform": "OS-Plattform",
            "cookie": "Cookie-Einstellungen"
        },
        "logo": {
            "title": "CI CONFIGURATOR"
        },
        "misc": {
            "back": "Zurück",
            "continue": "Weiter",
            "capture": "Bild Herunterladen"
        },
        "country": {
            "title": "Hinweis",
            "text": "Sie haben ein anderes Land als Lieferadresse angegeben. Wir aktualisieren Währung und Mehrwertsteuer entsprechend.",
            "close": "Fortfahren"
        },
        "errors": {
            "network": "Fehler beim Verbinden mit dem Server!",
            "notfound": "Dieser Code wurde leider nicht gefunden!",
            "unauthorized": "Sie sind nicht berechtigt, auf diese Ressource zuzugreifen."
        }
    }
};

export default translation;