import { LibraryData, ProductState, PricingData, CountryData } from "../store/interfaces";

export function getPricing(price: PricingData): number {
    return price.eur;
}
export function getPricingText(language: string, country: CountryData, price: PricingData): string {
    let locale = 'de-DE';
    if (language === 'en') {
        locale = 'en-US';
    } else if(language === 'fr') {
        locale = 'fr-FR';
    }
    let temp = 0;
    if(country.id === 'ch') {
        temp = price.chf + 0.001;
    } else {
        temp = price.eur + 0.001;
    }
    return temp.toLocaleString(locale, {style: 'currency', currency: country.currency});
}

export function getPricingTextLong(language: string, country: CountryData, price: PricingData): string {
    let locale = 'de-DE';
    if (language === 'en') {
        locale = 'en-US';
    } else if(language === 'fr') {
        locale = 'fr-FR';
    }
    let temp = 0;
    if(country.id === 'ch') {
        temp = price.chf + 0.001;
    } else {
        temp = price.eur + 0.001;
    }
    return temp.toLocaleString(locale, {style: 'currency', currency: country.currency, currencyDisplay: 'code'});
}

export function addPrice(a: PricingData, b: PricingData) : PricingData {
    const pricing: PricingData = Object.assign({
        chf: 0,
        eur: 0
    }, a);
    pricing.chf += b.chf;
    pricing.eur += b.eur;
    return pricing;
}

export function multiplyPrice(a: PricingData, b: number) : PricingData {
    const pricing: PricingData = Object.assign({
        chf: 0,
        eur: 0
    }, a);
    pricing.chf *= b;
    pricing.eur *= b;
    return pricing;
}
export function applyVat(a: PricingData, vat: number) : PricingData {
    const pricing: PricingData = Object.assign({
        chf: 0,
        eur: 0
    }, a);
    pricing.chf += pricing.chf * vat;
    pricing.eur += pricing.eur * vat;
    return pricing;
}
export function getVat(a: PricingData, vat: number) : PricingData {
    const pricing: PricingData = Object.assign({
        chf: 0,
        eur: 0
    }, a);
    pricing.chf = pricing.chf * vat;
    pricing.eur = pricing.eur * vat;
    return pricing;
}

export function getFinalPricingNoVat(library: LibraryData, products: ProductState[]) {
    let pricing: PricingData = Object.assign({
        chf: 0,
        eur: 0
    });
    products.forEach((product) => {
        let article = library.products.find((article) => {
            return article.article === product.article;
        });
        if(article === undefined) {
            return false;
        }
        let overallAmount = 0;
        product.order.forEach(order => {
            overallAmount += order.amount;
        });

        let activePrice = article.pricing[0].pricing;
        for (let index = article.pricing.length - 1; index >= 0; index--) {
            const element = article.pricing[index];
            if(overallAmount >= element.minAmount) {
                activePrice = element.pricing;
                break;
            }
        }
        
        product.logos.forEach((logo) => {
            let logoArea = library.logoAreas.find((area) => {
                return area.id === logo.logoAreaId;
            })

            if(logoArea === undefined) {
                return;
            }
            
            let logoPrice: PricingData|null = null;
            for (let index = logoArea.pricing.length - 1; index >= 0; index--) {
                const element = logoArea.pricing[index];
                if(overallAmount >= element.minAmount) {
                    logoPrice = element.pricing;
                    break;
                }
            }

            if(logoPrice === null) {
                return;
            }
            
            activePrice = addPrice(activePrice, logoPrice);
        });

        pricing = addPrice(pricing, multiplyPrice(activePrice, overallAmount));
    }) ;
    return pricing;
}


export function getShippingPrice(library: LibraryData, price: PricingData) {
    const pricing: PricingData = {
        chf: 0,
        eur: 0
    };

    /*
    Todo(daniel):
    for (let i = 0; i < library.shippingPrice.length; i++) {
        const shipping = library.shippingPrice[i];
        if(price.eur >= shipping.minPricing.eur * vatFactor) {
            pricing.eur = shipping.costs.eur * vatFactor;
            break;
        }
    }
    for (let i = 0; i < library.shippingPrice.length; i++) {
        const shipping = library.shippingPrice[i];
        if(price.chf >= shipping.minPricing.chf * vatFactor) {
            pricing.chf = shipping.costs.chf * vatFactor;
            break;
        }
    }
    */
    return pricing;
}
