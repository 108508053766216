import React from "react";

type ErrorProps = {
    errorMessage: string|null;
    children: React.ReactNode;
}

type ErrorState = {
    hasError: boolean;
  };
  
export default class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
    constructor(props: ErrorProps) {
        super(props);
        this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
  
    componentDidCatch(error: any, errorInfo: any) {
        
    }
  
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            if(this.props.errorMessage == null) {
                return <icosahedronGeometry args={[0.1, 1]}/>
            }
            return <p>{this.props.errorMessage}</p>;
        }
      
        return this.props.children; 
    }
  }